import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1 } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';

const Container = styled.div`
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const Case1Guide: FC<RouteComponentProps> = (props) => {
  const staticData = useStaticQuery(graphql`
    query {
      aobGuide1: file(relativePath: { eq: "aob-guide-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <PublicRoute {...props}>
      <Container>
        <SEO title="FAQs" />
        <H1 className="mb-24" color="#051392">
          Guide to Case #1 AOB creation (screenshot)
        </H1>

        <div className="flex items-center justify-center">
          <div className="w-full">
            <Img fluid={staticData.aobGuide1.childImageSharp.fluid} />
          </div>
        </div>
      </Container>
    </PublicRoute>
  );
};

export default Case1Guide;
